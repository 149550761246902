@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@300;400;500;600;700;800&family=Noto+Sans+Sinhala:wght@100;200;300;400;500;600&display=swap");

* {
  margin: 0px;
}
li {
  font-family: "Noto Sans Sinhala", sans-serif;
  font-weight: 500;
}
#hasinduOnline {
  color: #146c43;
}
a:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
  color: #14532d !important;
}
#button-addon2:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 10px 10px 38px 3px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 10px 10px 38px 3px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 10px 10px 38px 3px rgba(0, 0, 0, 0.49);
}
#podiTitle {
  color: #146c43;
}
body {
  background-image: url("img/AnimatedShape.svg");
  background-repeat: no-repeat;
  background-size: auto;
}
#mainDiv {
  background-position: 70% 10%;
  background-size: 50px;
  background-image: url("img/ooorganize.svg");
  background-repeat: repeat;
  background-size: cover;
}
#defe {
  color: #146c43;
}
#usersearch {
  color: #21252980;
}
